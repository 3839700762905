// Input
.ant-form-large .ant-form-item .ant-form-item-label > label {
  height: unset;
}

.ant-layout {
  // sider
  .ant-layout-sider {
    background-color: #215493;

    .ant-menu {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .ant-menu::-webkit-scrollbar {
      display: none;
    }

    .ant-menu-light {
      background-color: #215493;
    }

    .ant-menu-item {
      color: #fff;

      &:hover {
        color: #fff !important;
      }
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    .ant-menu-submenu-title {
      color: #fff;
    }

    .ant-menu-item-selected {
      background-color: #7a98be;
    }

    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        background-color: #044594;
      }
    }
  }
  // header
  .ant-layout-header {
    background-color: #fff;
    padding: 0;
  }
}

// table
.ant-table-thead {
  th {
    text-align: center !important;
    background-color: #c7dcf5 !important;
  }
}

.ant-pagination {
  pageItem {
    @apply bg-gray-light-2 border-none rounded-md h-9 w-9 leading-9;
  }

  @apply relative w-full flex justify-center items-center my-4;

  .ant-pagination-prev {
    @apply h-9 absolute left-0 top-0;
  }
  .ant-pagination-next {
    @apply h-9 absolute right-0 top-0;
  }
  .ant-pagination-item-active {
    @extend pageItem;
  }
  .ant-pagination-item {
    @apply h-9 w-9 leading-9;

    &:hover {
      @extend pageItem;
    }
  }
}

// checkbox
.custom-checkbox-checked .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4759ff !important;
  border-color: #4759ff !important;
}

.ant-form-item-label > label {
  font-size: 14px !important;
}
.ant-modal-content {
  border-radius: 16px !important;
  padding: 0 !important;
}

.ant-modal-body {
  padding: 20px 24px 0px 24px !important;
}

.ant-modal-footer {
  padding: 0 24px 20px 24px !important;
}
.ant-modal-header {
  border-radius: 16px !important;
}

.ant-table-cell {
  white-space: nowrap;
  max-width: 280px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.ant-pagination-options {
  display: none !important;
}

.input-right-align {
  text-align: right !important;
  .ant-input-number-input-wrap {
    margin-right: 24px !important;
  }
  input {
    text-align: right !important;
  }
  .ant-input-number-input {
    padding: 7px 0 !important;
  }
}
