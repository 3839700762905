@tailwind base;
@tailwind components;
@tailwind utilities;

@import './antd-custom.scss';

:root {
  font-size: 16px;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.ellipsis-text {
  @apply whitespace-nowrap overflow-hidden text-ellipsis;
}
